/*

npm i -g npm-check-updates
ncu -u
npm install

*/

const protocol = window.location.protocol;
const hostname = window.location.hostname;
const port = window.location.port;

let a, s, af, sf, pusher_app_key;

af = "https://api-timbrado.acrescoweb.com/public/api/";
sf = "https://api-timbrado.acrescoweb.com/public/";

switch (hostname) {
	case 'localhost':
	case '127.0.0.1':
		a = 'https://apitimbrado.facturamx.com/public/api/';
		s = 'https://apitimbrado.facturamx.com/public/';
		af = 'http://localhost/api_cfdi/public/api/';
		sf = 'http://localhost/api_cfdi/public/';
		pusher_app_key = '80a4bafad209749734c6';
		break;
	case 'portal.facturamx.com':
		a = 'https://portal-api.facturamx.com/public/api/';
		s = 'https://portal-api.facturamx.com/public/';
		pusher_app_key = '80a4bafad209749734c6';
		break;
	default:
		a = protocol + '//api' + hostname + '/public/api/';
		s = protocol + '//api' + hostname + '/public/';
		pusher_app_key = '6de7ef05c9261dbb1404';
}

const api = a;
const src = s;
const api_facturacion = af;
const src_facturacion = sf;

const basename = hostname === 'localhost' ? (Number(port) === 3000 || Number(port) === 3001 || Number(port) === 3002 || Number(port) === 3003 ? '' : '/facturax/build/') : '/';

export const CONFIG = {
	prefix: 'facturamx',
	id_project: 2,
	api_facturacion: api_facturacion,
	src_facturacion: src_facturacion,
	api: api,
	src: src,
	basename: basename,
	foto_default: src + 'file/perfil/default-perfil.svg',
	portada_default: src + 'file/portada/default-portada.svg',
	menu: 1,
	debug: false,
	pusher_app_key: pusher_app_key,
	pusher_app_cluster: 'us2',
	pusher_app_force_tls: true,
	pusher_app_log_to_console: true,
	segundo_confirmar_token_expirado: 30,
	tipo_menu_contextual: 2,
	onesignal_hostname_prod: 'portal.facturamx.com',
	onesignal_app_id_prod: '6b907ea6-0d3a-4d23-8cb5-6b2c5b94ba8e',
	onesignal_app_id_test: '5e077659-5d4d-4420-9e62-f4a96330c4d6',
	titulo_alert_confirm: 'FacturaMX',
	
	DROPBOX_TOKEN: "Zto1IdH0HsAAAAAAAAAAEf3ZBqVsxJoNqmcp2-zRmCl6GkG7RpIDpAwShiiEY5je",
	DROPBOX_API: "https://api.dropboxapi.com/2/files/",
	DROPBOX_CONTENT: "https://content.dropboxapi.com/2/files/",
};
